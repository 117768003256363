export const siteIdsForAdminCheckout = [
  '6f973589-27dc-4e1e-8419-76f5360f0a7b', // 17th and Central
  '7d6ecb93-b34c-4cf6-99e1-fffc958fec68', // 49dollaridahoregisteredagent.com
  '8f3d6894-7ce0-4b77-adb2-6df35a97c58c', // A Registered Agent LLC
  '781045c1-6edf-43d6-bf70-33a55c77e5b0', // ActiveFilings LLC
  '3dbfa335-173d-417e-a9a1-14a1cb2627bd', // alabamaregisteredagent.com
  'f1e74596-e6c2-4972-a285-63304dce68f2', // alaskaregisteredagent.com
  '5f1068f2-48cd-4df6-beb5-187a9e504106', // All Day $49 Montana Registered Agent LLC.
  '1cc6491e-3627-47df-a143-9348a3d06547', // Arizona Statutory Agent
  'f464adf9-b024-4d18-9698-85b7f1c8ecab', // Arizona Registered Agent LLC
  '785458e9-d8aa-4dab-a9a8-59cda37bd483', // arkansasregisteredagent.com
  '2bb186f9-b10b-44a0-a030-2e91f680a5df', // Best Delaware Registered Agent
  '27e69b2f-dbbd-487d-aa23-3bab58769612', // bestwyomingregisteredagent.com
  'bd7728c1-5de7-4902-9a75-fb148ae90719', // cacorporateagents.com
  '69773144-796f-40e4-9bba-b08f06941380', // californiaagentforserviceofprocess.com
  '89e7e19e-926f-453b-82db-a9b758eb0c12', // californiaregisteredagent.com
  'f19ff807-f306-4d63-86da-2a44905d6c05', // californiaregisteredagents.net
  'dd4526c0-4a41-4ea5-a8b4-d6de0c172bf2', // california-registered-agent.net
  '56637341-7e16-4f45-9027-4db859a28e17', // californiaregisteredagent.net
  'aefda76c-b5af-4e16-bc6a-a97742a48385', // cheapestdelawareregisteredagent.com
  '9c547e1d-c273-4e1d-9299-89eed0dd356e', // Colorado LLCs
  '920b8e2d-f945-433c-8a0a-0f668954ea2b', // Colorado Registered Agent LLC
  'afd9bf14-d4d2-44dc-937d-a3d4dd38758e', // Commercial Registered Agent LLC
  'd40a2b18-402a-48d6-a13c-452ad3033396', // Corporation Registered Agent LLC
  '5d54b211-62e0-4f89-9fa5-c67737b56b5f', // Corp-Kit Northwest Inc
  'e0debece-0d49-40e8-8a1e-f7fc9c53d5b3', // Dash Incorporators, LLC
  '55be8da8-eb3d-436a-96f1-5e24cc8cc33e', // Delaware Registered Agent Service LLC
  '8916fbd4-39d1-4e51-82b8-c828d9cd5a93', // floridaregisteredagent.com
  'e8c6f7ce-f775-4c43-8628-abc428fe1b1e', // floridaregisteredagent.net
  'c1dc2f5e-1c51-4fe0-80c8-2a2846d24c6f', // Form A Corp
  '80cf0591-6342-419a-adb9-f7063351f6dd', // Formed Inc
  '82905923-4d46-49a6-a5d3-9bd1853dd3e9', // georgiaregisteredagent.com
  'b17408d3-13ae-4da2-95b0-03d06af0647b', // hawaiiregisteredagent.com
  'c8469731-fdd8-405a-a9ce-7f3a6b8d83c7', // High Desert Corporate Filings LLC
  'c20cdaf3-d6d7-4391-a14b-2a39590b4dff', // How to start an LLC
  '7e927a4b-83c9-47d8-b130-e0e01ec02f1d', // idahoregisteredagent.com
  '97824a4a-cc8c-4157-a8ba-2ba1c4ba66ca', // illinoisregisteredagent.net
  '7e289c27-4010-4250-bcc5-e33eefd8f84e', // Incorporate Fast Inc
  '30cb2ca5-c6b4-453f-bff3-934c31fb3d66', // Incorporate Me LLC
  'f876dd71-625b-410a-bfff-049d6da7e011', // indianaregisteredagent.com
  '3d339dfe-ad60-42bd-918a-549ed0b4f0ff', // iowaregisteredagent.com
  '3074e416-989e-436b-aa99-7271b6a8c77f', // kansasregisteredagent.com
  '7c6ccee5-cfc4-4355-873d-ef257d0ac4be', // kentuckyregisteredagent.com
  '3e0a9f25-0771-48b9-b097-6c1f3daaa96b', // Lawyer Agents
  'c9b08672-4fdb-4cb0-96d0-5a7acdec0178', // legalregisteredagentservices.com
  '8bc18b91-6d34-4851-8386-fd63d4cb2323', // LLC Registered Agent LLC
  '21b2d158-e324-47ec-981b-2db81456529f', // Lone Star Registered Agent LLC
  '82f7b54d-8b39-4e5f-a7ca-6b1583936f6e', // louisianaregisteredagent.com
  '3aba4739-0568-41ae-8efc-da3139c8fab8', // maineregisteredagent.com
  '28475959-6b9f-4d27-acb7-9baf71d64005', // Maryland Registered Agent Inc
  '2704fd55-ac6d-4052-b278-4e1b29a1214b', // Maryland Resident Agent Inc
  '2cdd240d-8f79-4a10-b62e-a8da9ab1f5be', // massachusettsregisteredagent.com
  'ee423ba5-fdaa-4c73-b962-0504745ea447', // michiganregisteredagent.com
  'eb82ff73-2048-4247-a730-193b39e7060a', // minnesotaregisteredagent.com
  '6fae6da5-09e6-41f6-b67a-931b41e8e38e', // mississippiregisteredagent.com
  '33ba8be7-fdc5-4225-b5e9-d82127a2c91c', // missouriregisteredagent.com
  '38b0a94b-089f-4f39-8830-6b1abd52720e', // Montana Registered Agent LLC
  '806c3c6e-f164-420b-81a6-8b51fbd50e68', // Nationwide Registered Agent Services LLC
  '983dca8e-433f-468a-b35d-d8fdcaa1cbe6', // nebraskaregisteredagent.com
  'e7b338c2-e07d-4cf5-a40a-c24bf7dbcbb4', // nevadaresidentagent.com
  'a1abe701-040f-42da-b90f-146d73a43902', // newhampshireregisteredagent.com
  'd662d5b0-3189-418b-9343-ef72f8ef60ff', // newjerseyregisteredagent.com
  '43992baa-ccaf-44c8-b15e-fbcb4863cd73', // New Mexico LLCs
  'de1d12e8-6b33-458b-872c-57471564960e', // newyorkllcs.com
  '071b22d6-122c-4084-a256-fc46a7517abc', // newyorkregisteredagent.com
  '61038b33-fdc2-484f-b5dd-7e81b02bd3cd', // northcarolinaregisteredagent.net
  'b93ac925-0e54-45c7-adff-0eb3d56f4065', // northdakotaregisteredagent.com
  '1d13c8d3-e2a7-4da6-86f6-61de5a504aed', // northdakotaregisteredagent.net
  '1cce3af8-d85c-4f0b-9b4f-aa7193df2729', // ohioregisteredagent.com
  'e1073f79-9dbf-4457-b8e8-d0b2ec55366f', // ohiostatutoryagent.com
  '8b8ac4c0-66e6-47de-9e97-bd26e90d1bc5', // oklahomaregisteredagent.com
  'fb333b28-3e32-45cb-b9d7-128bea1d7cc7', // oregonllcs.com
  'fee04002-ab4b-4dd9-9b31-f8a0d7bc95ef', // oregonregisteredagent.com
  'f3b3f925-acca-495d-bb8f-d726f401c373', // pacommercialregisteredofficeprovider.com
  '144368f4-15cc-458a-ba8c-59f7f4438097', // pennsylvaniaregisteredagent.com
  'cec4a776-14cd-4723-a72a-b382de74583e', // Process Agent LLC
  'ab79bb8e-5d1d-4b93-9c5f-18bc9786b5ef', // puertoricoregisteredoffice.com
  '0efc0629-80b2-4f74-ad1b-870bcf932d24', // RA Services LLC
  '50ae5a31-2322-4e7e-98a9-236edd45162c', // Registered Agent Office LLC
  '6b1eefda-b1b6-43fd-9020-573d97abb825', // Registered Agent Services Inc
  '5f50851c-f6f4-4465-a8ce-d4b4f1bf8448', // Resident Agent LLC
  '0033295b-bf69-4bf2-9bd7-e3d646bba939', // rhodeislandregisteredagent.com
  '2b11dd7e-3935-4b08-8b2c-2fcac1257db8', // southcarolinaregisteredagent.com
  'f87f0420-de32-4e5b-8291-53b7dc13957c', // southdakotaregisteredagent.com
  '1b1f4a83-dcec-434a-9682-65d54ecf14bd', // Speedy Incorporation and LLC
  '7aba8034-50b9-44dc-9378-f814f1ab6409', // Statutory Agent LLC
  '756319a0-05d6-44de-9dc0-99c6108d91cc', // tennesseeregisteredagent.com
  'c1ad9c2d-1da1-4986-808a-76eacbda4829', // Texas Registered Agent
  '259dcc5e-2071-430d-a5bf-c33265cdc81b', // Texan Registered Agent LLC
  '898cb5e0-5269-4ed9-a933-8bf29107024d', // utahregisteredagent.com
  '5a5021c2-546d-4726-96d9-ae5c43e35f0b', // vermontregisteredagent.com
  'b4c4f21c-8a6e-4d6c-915d-bc0e2c14e30d', // virginia-registeredagent.net
  'dbbeb33f-3245-433e-8e44-6ae3510f4662', // washingtonregisteredagent.com
  '8c1d640e-cf46-4821-819e-0d211c7b4e7e', // washingtonregisteredagent.net
  '212ddfe1-5e2b-4df5-9da2-284a27db848c', // washingtondcregisteredagent.com
  '6b4d46bb-3dd5-498c-8362-37e93e795eae', // westvirginiaregisteredagent.com
  '320e0b78-9678-42c2-847c-c211ffb767a5', // wisconsinregisteredagent.net
  'ec7ddb7a-3b7a-4a32-80c5-abfcc45548a9', // wyomingagents.com
  'e24fe146-bc29-46eb-88d6-1c7b65730dbf', // wyomingmailforwarding.com
  '08fc3bb5-c1af-4255-8b9f-0abac157c582', // wyregisteredagent.net
  '93ada064-0668-4ea5-be96-b2d9178e3f12', // Wyoming Registered Agents
  'f8dd0f7b-e8fc-409a-b3a0-074c84bf297a', // Wyoming Registered Agents Inc
]
